import clsx from 'clsx';
import {
  ChangeEvent,
  forwardRef,
  InputHTMLAttributes,
  MouseEventHandler,
  ReactElement,
  useEffect,
  useRef,
  useState,
} from 'react';

export type InputTypes = Omit<
  InputHTMLAttributes<HTMLInputElement>,
  'onChange' | 'type'
> & {
  key?: string;
  label?: string;
  caption?: string | ReactElement<any, any>;
  error?: string;
  leftIcon?: string | ReactElement;
  rightIcon?: string | ReactElement;
  placeholder?: string;
  clearable?: boolean;
  value?: string;
  onChange?: (value: string, e?: ChangeEvent) => void;
  disabled?: boolean;
  backgroundColor?: string;
  type?: 'text' | 'textarea' | 'password' | 'tel' | 'email' | 'number';
  readOnly?: boolean;
  className?: string;
  wrapperClassName?: string;
  onRightIconClick?: MouseEventHandler;
  onLeftIconClick?: MouseEventHandler;
  autoCheck?: boolean;
  labelClassName?: string;
  disableFunctions?: boolean;
  rows?: number;
  optional?: boolean;
};

const Input = forwardRef<HTMLInputElement | HTMLTextAreaElement, InputTypes>(
  (
    {
      label,
      caption,
      error: errorProps,
      leftIcon,
      rightIcon,
      placeholder,
      clearable,
      value = '',
      onChange,
      disabled,
      // backgroundColor = 'white',
      type = 'text',
      readOnly,
      className = '',
      wrapperClassName = '',
      onRightIconClick = () => void 0,
      onLeftIconClick,
      autoCheck = true,
      labelClassName = '',
      name,
      onBlur,
      disableFunctions,
      rows = 3,
      optional,
      ...inputProps
    },
    ref
  ) => {
    const [error, setError] = useState(errorProps);
    const inputRef = useRef(null);

    function changeHandler(
      e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
    ) {
      if (disabled) return;
      const textValue: string = e.target.value;
      if (onChange) {
        onChange(textValue, e);
        return;
      }
    }

    function clear() {
      if (onChange) onChange('');
      inputRef.current.focus();
    }

    function labelClickHandler() {
      if (inputRef) inputRef?.current?.focus();
    }

    function checkValidity(e) {
      if (!autoCheck) return;

      const { valid } = e.target.validity;

      if (!valid) {
        setError(errorProps || e.target.validationMessage);
      } else {
        if (!errorProps) setError('');
      }
    }

    function renderLeftIcon() {
      if (!leftIcon) return null;
      if (typeof leftIcon === 'string')
        return <i onClick={onLeftIconClick} className={leftIcon} />;
      return leftIcon;
    }

    function renderRightIcon() {
      if (!rightIcon) return null;
      if (typeof rightIcon === 'string')
        return <i onClick={onRightIconClick} className={rightIcon} />;
      return rightIcon;
    }

    useEffect(() => {
      setError(errorProps);
    }, [errorProps]);

    return (
      <div className={wrapperClassName}>
        <label
          className={`block text-regular1 mb-8 ${
            disabled && !disableFunctions ? 'text-grey' : 'text-ink font-medium'
          } ${labelClassName}`}
          onClick={labelClickHandler}
          id={name}
        >
          <span>{label}</span>
          {optional && <span className='text-small1 text-ink-lightest/70'> (Optional)</span>}
          {inputProps.required && <sup className='text-red-500'>*</sup>}
        </label>
        <div
          className={clsx(
            'relative rounded-lg shadow-sm overflow-hidden',
            disabled && 'cursor-not-allowed !bg-gray-100 !text-gray-400'
          )}
        >
          <div
            className={`absolute inset-y-0 left-16 flex items-center ${
              onLeftIconClick ? 'pointer-events-auto' : 'pointer-events-none'
            } ${disabled && !disableFunctions ? '!bg-gray-100 !text-gray-400' : 'text-ink'}`}
          >
            {renderLeftIcon()}
          </div>
          {type === 'textarea' ? (
            <textarea
              name={name}
              className={clsx(
                'block w-full outline-none text-ink pt-12 rounded-lg border disabled:border-grey-lighter focus:border-primary bg-white placeholder-grey-dark',
                !leftIcon ? 'pl-16' : 'pl-42',
                !rightIcon ? 'pr-16' : 'pr-42',
                error
                  ? 'border-danger disabled:border-danger'
                  : 'border-grey-light',
                className,
                disabled && 'cursor-not-allowed !bg-gray-100 !text-gray-400'
              )}
              rows={rows}
              placeholder={placeholder}
              onChange={changeHandler}
              disabled={disabled}
              ref={ref ?? inputRef}
              defaultValue={value} // visit this later
              {...(!ref && { value })}
            />
          ) : (
            <input
              name={name}
              type={type}
              className={clsx(
                'block w-full outline-none h-48 rounded-lg border disabled:border-grey-light focus:border-primary placeholder-grey-dark border-grey-light bg-white',
                !leftIcon ? 'pl-16' : 'pl-42',
                !rightIcon ? 'pr-16' : 'pr-42',
                error
                  ? 'border-danger disabled:border-danger'
                  : 'border-grey-light',
                disabled && disableFunctions
                  ? '!bg-gray-100 !text-gray-400'
                  : 'text-ink',
                className
              )}
              placeholder={placeholder}
              onChange={changeHandler}
              disabled={disabled}
              readOnly={readOnly}
              ref={ref ?? inputRef}
              {...(!ref
                ? {
                    value,
                    onBlur: checkValidity,
                  }
                : {
                    onBlur,
                  })}
              {...inputProps}
            />
          )}
          <div
            className={clsx(
              'absolute inset-y-0 right-16 flex items-center',
              disabled && !disableFunctions ? 'text-grey' : 'text-ink'
            )}
            onClick={onRightIconClick}
          >
            {renderRightIcon()}
            {clearable && (
              <a
                className={`block ${
                  !(disabled && !disableFunctions) && 'cursor-pointer'
                }`}
                onClick={clear}
              >
                <i className="ri-close-line" />
              </a>
            )}
          </div>
        </div>
        {(error || caption) && (
          <p
            className={`block text-sm ${
              error ? 'text-danger' : 'text-ink'
            } text-tiny1 my-8 ${
              disabled && !disableFunctions ? 'text-grey' : ''
            }`}
          >
            {error || caption}
          </p>
        )}
      </div>
    );
  }
);
Input.displayName = 'Input';

export default Input;
