import Image from 'next/legacy/image';
import OnboardLogo from 'public/images/company_logo/logo_regular_green.svg';
import { default as SecondaryLayout } from 'components/layout/secondary/SecondaryLayout';
import Input from 'components/forms/Input';
import PasswordField from 'components/forms/PasswordField';
import Button from 'components/Button';
import { useRouter } from 'next/router';
import {
  useCallback,
  useContext,
  useEffect,
  useLayoutEffect,
  useMemo,
  useState,
} from 'react';
import Link from 'next/link';
import config from 'utils/config';
import API from 'utils/api';
import { destroyCookie, parseCookies, setCookie } from 'nookies';
import { cookies } from 'utils/constants';
import nonAuth from 'utils/nonAuth';
import errorParser from 'utils/errorParser';
import { AppContext } from './_app';
import { setCompanyData, setUserData } from 'stores/actions';
import APIv2 from 'utils/api/v2';

const LoginPage = () => {
  const router = useRouter();
  const [error, setError] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [loading, setLoading] = useState(false);
  const { dispatch } = useContext(AppContext);

  const token = parseCookies()?.[cookies.token];

  useEffect(() => {
    if (token) {
      router.push('/dashboard');
    }
  }, []);

  const disabled = useMemo(() => {
    return !email || !password || loading;
  }, [email, password, loading]);

  const submit = useCallback(
    (e) => {
      e.preventDefault();
      setError('');
      setLoading(true);

      APIv2.login({
        email: email.toLowerCase(),
        password,
      })
        .then(async (res) => {
          const { data, status } = res;
          if (data?.token && status === 201) {
            setCookie(null, cookies.token, data.token, {
              maxAge: 365 * 24 * 60 * 60 /* a year */,
              ...config.cookiesConfig,
            });
            await APIv2.getCompanyV2({
              withCredentials: true,
            })
              .then((companyRes) => {
                dispatch(setCompanyData(companyRes.data));

                if (companyRes?.data?.errors?.is_otp_verified) {
                  router.push('/verification');
                } else if (!companyRes?.data?.verification_status) {
                  router.push('/document-verification');
                } else {
                  router.push('/dashboard');
                }
                // } else {
                //   destroyCookie({}, cookies.token, config.cookiesConfig);
                //   setError(
                //     "You're not allowed to login with this type of account"
                //   );
                // }

                setLoading(false);
              })
              .catch((err) => {
                destroyCookie({}, cookies.token, config.cookiesConfig);
                setLoading(false);
                alert(errorParser(err.response.data));
              });
          } else {
            setLoading(false);
            setError(errorParser(res.data));
          }
        })
        .catch(() => {
          setError('Your email or password did not match.');
          setLoading(false);
        });
    },
    [email, error, password, loading]
  );

  if (token) {
    return <></>;
  }

  return (
    <>
      <div className='relative'>
        <Link href="/" passHref>
          <Image
            src={OnboardLogo}
            width={170}
            height={80}
            alt="logo"
          />
        </Link>
      </div>
      <h1 className="text-xlarge1 font-medium">
        Login to Crewing Dashboard
      </h1>
      <p className="text-small3 mt-8 text-ink-lightest">{config.tagline}</p>
      <form onSubmit={submit}>
        <div className="mt-36">
          <Input
            label="Email/Username"
            placeholder="Your email/Username"
            wrapperClassName="mb-24"
            onChange={setEmail}
            value={email}
            error={error}
            required
          />
          <PasswordField
            onChange={setPassword}
            value={password}
            placeholder="Password"
            required
          />
          <p className="text-right mt-16 mb-16">
            <Link href="/forgot" passHref>
              Forgot Password?
            </Link>
          </p>
          <Button
            type="submit"
            text="Login"
            block
            size="large"
            disabled={disabled}
          />
          <p className="mt-16 text-center text-ink-lightest">
            Don&apos;t have an account?{' '}
            <Link href="/register" passHref>
              Register here
            </Link>
          </p>
        </div>
      </form>
    </>);
};

LoginPage.getLayout = (page) => (
  <SecondaryLayout title="Login">{page}</SecondaryLayout>
);

export default nonAuth(LoginPage, false);
