import Image from 'next/legacy/image';
import Background from 'public/images/bg_login.jpg';
import styles from 'styles/components/SecondaryLayout.module.css';
import MainHead from 'components/layout/main/MainHead';

const SecondaryLayout = ({ children, title }) => {
  return (<>
    <MainHead title={title} />
    <div className={`grid overflow-hidden h-screen justify-items-center ${styles.wrapper}`}>
      <div className="h-full w-full relative hidden md:block">
        <Image src={Background} placeholder="blur" alt="Ship" layout="fill" objectFit="cover" />
      </div>
      <div className="w-full h-screen grid overflow-auto">
        <div className="max-w-screen-ipad mx-auto self-start tablet:self-center px-16 tablet:px-0 py-24 mb-32">
          {children}
        </div>
      </div>
    </div>
  </>);
};

export default SecondaryLayout;
